<template>
  <div>
    <b-card>
      <AgGrid
        ref="ABIPartiesGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <b-button
            v-b-tooltip.hover="'New Party'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="CreateNewParty()"
          ><font-awesome-icon
             :icon="['far', 'plus-square']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">New Party</span></b-button>
        </template>

      </AgGrid>
    </b-card>

    <b-modal
      id="CreatePartyModal"
      ref="CreateParty"
      title="New Party"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Save"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="SaveNewParty"
      >
        <tab-content
          title="Detail"
          :before-change="validateDetail"
        >
          <validation-observer
            ref="detailRules"
            tag="form"
          >
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Customer"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Customer:</b></i></label>
                      <v-select
                        id="CustomerOption"
                        v-model="party.CustomerId"
                        :options="CustomerOptions"
                        label="name"
                        placeholder="Select a Customer"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Company Type"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false:null">
                      <label class="required"><i><b>Company Type:</b></i></label>
                      <v-select
                        id="PartyTypeOption"
                        v-model="party.PartyType"
                        :options="PartyTypeOptions"
                        label="text"
                        placeholder="Select a type"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Party Name"
                    rules="required|max:32"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Name:</b></i></label>
                      <b-form-input
                        id="PartyName"
                        v-model="party.PartyName"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Party Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Full Legal Name"
                    rules="required|max:100"
                  >
                    <label class="required"><i><b>Full Legal Name:</b></i></label>
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <b-form-input
                        id="LegalName"
                        v-model="party.PartyFullName"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Legal Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="DBA"
                    rules="max:32"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>DBA:</b></i></label>
                      <b-form-input
                        id="DBA"
                        v-model="party.DBA"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="DBA"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Aka"
                    rules="max:100"
                  >
                    <label><i><b>Aka:</b></i></label>
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <b-form-input
                        id="Aka"
                        v-model="party.Aka"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Aka"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Country"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Country:</b></i></label>
                      <v-select
                        id="PartyCountryOption"
                        v-model="party.PartyCountry"
                        :options="CountryOptions"
                        label="country"
                        placeholder="Select a Country"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Parent Company"
                    rules="max:32"
                  >
                    <label><i><b>Parent Company:</b></i></label>
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <b-form-input
                        id="ParentCompany"
                        v-model="party.ParentCompany"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Parent Company"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content
          title="Types"
          :before-change="validateType"
        >
          <validation-observer
            ref="typeRules"
            tag="form"
          >
            <b-row>
              <b-col md="3">
                <b-form-checkbox v-model="party.IsImporter">
                  Importer
                </b-form-checkbox>
              </b-col>
              <b-col md="3">
                <b-form-checkbox v-model="party.IsConsignee">
                  Consignee
                </b-form-checkbox>
              </b-col>
              <b-col md="3">
                <b-form-checkbox v-model="party.IsShipTo">
                  Ship To
                </b-form-checkbox>
              </b-col>
              <b-col md="3">
                <b-form-checkbox v-model="party.IsBuyer">
                  Buyer
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-checkbox v-model="party.IsSeller">
                  Seller
                </b-form-checkbox>
              </b-col>
              <b-col md="3">
                <b-form-checkbox v-model="party.IsManufacturer">
                  Manufacturer
                </b-form-checkbox>
              </b-col>
              <b-col md="3">
                <b-form-checkbox v-model="party.IsConsolidator">
                  Consolidator
                </b-form-checkbox>
              </b-col>
              <b-col md="3">
                <b-form-checkbox v-model="party.IsContainerStuffing">
                  Container Stuffing Location
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-checkbox v-model="party.IsBookingParty">
                  Booking Party
                </b-form-checkbox>
              </b-col>
              <b-col md="3">
                <b-form-checkbox v-model="party.IsNotifyParty">
                  Notify Party
                </b-form-checkbox>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content
          title="Identifiers"
          :before-change="validateIdentifier"
        >
          <validation-observer
            ref="identifierRules"
            tag="form"
          >
            <b-row>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Importer Number"
                    rules="max:12"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Importer #:</b></i></label>
                      <b-form-input
                        id="ImporterNumber"
                        v-model="party.ImporterNumber"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Importer Number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="EIN"
                    rules="max:12"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>EIN:</b></i></label>
                      <b-form-input
                        id="EIN"
                        v-model="party.EIN"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="EIN"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="CBP Assigned"
                    rules="max:12"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>CBP Assigned #:</b></i></label>
                      <b-form-input
                        id="CBPAssigned"
                        v-model="party.CBPAssigned"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="CBP Assigned"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="MID Number"
                    rules="max:15"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>MID #:</b></i></label>
                      <b-form-input
                        id="MIDNumber"
                        v-model="party.MIDNumber"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="MID Number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="SCAC"
                    rules="max:4"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>SCAC:</b></i></label>
                      <b-form-input
                        id="SCAC"
                        v-model="party.SCAC"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="SCAC"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="SSN"
                    rules="max:11"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>SSN:</b></i></label>
                      <b-form-input
                        id="SSN"
                        v-model="party.SSN"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="SSN"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Passport Number"
                    rules="max:12"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Passport #:</b></i></label>
                      <b-form-input
                        id="PassportNumber"
                        v-model="party.PassportNumber"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Passport Number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
        <tab-content
          title="Addresses"
          :before-change="validateAddress"
        >
          <validation-observer
            ref="addressRules"
            tag="form"
          >
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Mailing Address"
                    rules="required|max:32"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Mailing Address 1:</b></i></label>
                      <b-form-input
                        id="MailingAddress"
                        v-model="party.MailingAddress"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Mailing Address1"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Mailing Address 2"
                    rules="max:32"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Mailing Address 2:</b></i></label>
                      <b-form-input
                        id="MailingAddress2"
                        v-model="party.MailingAddress2"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Mailing Address 2"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Mailing City"
                    rules="required|max:23"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Mailing City:</b></i></label>
                      <b-form-input
                        id="MailingCity"
                        v-model="party.MailingCity"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Mailing City"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Mailing State"
                    rules="max:2"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Mailing State:</b></i></label>
                      <b-form-input
                        id="MailingState"
                        v-model="party.MailingState"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Mailing State"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Mailing Country"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false:null">
                      <label class="required"><i><b>Mailing Country:</b></i></label>
                      <v-select
                        id="MailingCountry"
                        v-model="party.MailingCountry"
                        :options="CountryOptions"
                        label="country"
                        placeholder="Select a country"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Mailing Zip"
                    rules="max:9"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Mailing Zip:</b></i></label>
                      <b-form-input
                        id="MailingZip"
                        v-model="party.MailingZip"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Mailing Zip"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Physical Address"
                    rules="required|max:32"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Physical Address 1:</b></i></label>
                      <b-form-input
                        id="PhysicalAddress"
                        v-model="party.PhysicalAddress"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Physical Address1"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Physical Address 2"
                    rules="max:32"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Physical Address 2:</b></i></label>
                      <b-form-input
                        id="PhysicalAddress2"
                        v-model="party.PhysicalAddress2"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Physical Address 2"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Physical City"
                    rules="required|max:23"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Physical City:</b></i></label>
                      <b-form-input
                        id="PhysicalCity"
                        v-model="party.PhysicalCity"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Physical City"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Physical State"
                    rules="max:2"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Physical State:</b></i></label>
                      <b-form-input
                        id="PhysicalState"
                        v-model="party.PhysicalState"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Physical State"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Physical Country"
                    rules="required"
                  >
                    <b-form-group :state="errors.length > 0 ? false:null">
                      <label class="required"><i><b>Physical Country:</b></i></label>
                      <v-select
                        id="PhysicalCountry"
                        v-model="party.PhysicalCountry"
                        :options="CountryOptions"
                        label="country"
                        placeholder="Select a country"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Physical Zip"
                    rules="max:9"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Physical Zip:</b></i></label>
                      <b-form-input
                        id="PhysicalZip"
                        v-model="party.PhysicalZip"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Physical Zip"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </tab-content>
      </form-wizard>
      <template #modal-footer>
        <div /></template>
    </b-modal>

  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import axios from '@axios'

import { required } from '@validations'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/ABI/ABIPartyViewBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  components: {
    AgGrid,
    FormWizard,
    TabContent,
  },
  data() {
    return {
      aggrid: [],
      required,
      party: {
        InACE: 'No',
        CustomerId: null,
        PartyID: this.$route.params.partyId,
        AddedBy: '',
        AddedDate: '',
        Aka: '',
        CBPAssigned: '',
        DBA: '',
        EIN: '',
        PartyFullName: '',
        ImporterNumber: '',
        LastUpdatedBy: '',
        LastUpdatedDate: '',
        MailingAddress: '',
        MailingAddress2: '',
        MailingCity: '',
        MailingCountry: null,
        MailingState: '',
        MailingZip: '',
        MIDNumber: '',
        PartyName: '',
        ParentCompany: '',
        PartyCountry: null,
        PartyType: null,
        PassportNumber: '',
        PhysicalAddress: '',
        PhysicalAddress2: '',
        PhysicalCity: '',
        PhysicalCountry: null,
        PhysicalState: '',
        PhysicalZip: '',
        SCAC: '',
        SSN: '',
        IsBookingParty: false,
        IsBuyer: false,
        IsConsignee: false,
        IsConsolidator: false,
        IsContainerStuffing: false,
        IsImporter: false,
        IsManufacturer: false,
        IsNotifyParty: false,
        IsSeller: false,
        IsShipTo: false,
        IsActive: true,
      },
      PartyTypeOptions: [
        { text: 'Corporation', value: 'C' },
        { text: 'Partnership', value: 'P' },
        { text: 'Individual', value: 'I' },
        { text: 'Sole Proprietor', value: 'S' },
        { text: 'Foreign Government', value: 'F' },
        { text: 'US Government', value: 'G' },
        { text: 'State Government', value: 'L' },
        { text: 'LLC', value: 'K' },

      ],
      CountryOptions: [],
      CustomerOptions: [],
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.aggrid = this.InitGridInstance('ABIParties')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'partyId',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        resizable: false,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      {
        headerName: 'In ACE', field: 'inACEFlag', filter: 'agSetColumnFilter', minWidth: 95,
      },
      {
        headerName: 'Active', field: 'activeFlag', filter: 'agSetColumnFilter', minWidth: 95,
      },

      {
        headerName: 'Party Detail',
        marryChildren: true,
        children: [
          { headerName: 'Name', field: 'name', minWidth: 300 },
          {
            headerName: 'Legal Name', field: 'fullLegalName', columnGroupShow: 'open', minWidth: 300,
          },
          {
            headerName: 'DBA', field: 'dba', columnGroupShow: 'open', minWidth: 150,
          },
          {
            headerName: 'AKA', field: 'aka', columnGroupShow: 'open', minWidth: 150,
          },
          {
            headerName: 'Country', field: 'partyCountry', minWidth: 110, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Parent Company', field: 'parentCompany', columnGroupShow: 'open', minWidth: 150,
          },
        ],
      },
      {
        headerName: 'Types',
        marryChildren: true,
        children: [
          {
            headerName: 'Importer', field: 'importerFlag', minWidth: 115, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Consignee', field: 'consigneeFlag', minWidth: 120, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Seller', field: 'sellerFlag', columnGroupShow: 'open', minWidth: 95, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Buyer', field: 'buyerFlag', columnGroupShow: 'open', minWidth: 90, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Manufacturer', field: 'manufacturerFlag', columnGroupShow: 'open', minWidth: 145, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Ship To', field: 'shipToFlag', columnGroupShow: 'open', minWidth: 105, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Consolidator', field: 'consolidatorFlag', columnGroupShow: 'open', minWidth: 135, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Container Suffer', field: 'containerStuffingFlag', columnGroupShow: 'open', minWidth: 165, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Booking Party', field: 'bookingPartyFlag', columnGroupShow: 'open', minWidth: 146, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'Notify Party', field: 'notifyPartyFlag', columnGroupShow: 'open', minWidth: 136, filter: 'agSetColumnFilter',
          },
        ],
      },
      {
        headerName: 'Party Identifiers',
        marryChildren: true,
        children: [
          { headerName: 'Importer Number', field: 'importerNumber', minWidth: 168 },
          { headerName: 'EIN', field: 'ein', minWidth: 136 },
          { headerName: 'CBP Assigned', field: 'cbpAssigned', minWidth: 105 },
          {
            headerName: 'MID', field: 'midNumber', columnGroupShow: 'open', minWidth: 105,
          },
          {
            headerName: 'SSN', field: 'ssn', columnGroupShow: 'open', minWidth: 105,
          },
          {
            headerName: 'Passport Number', field: 'passportNumber', columnGroupShow: 'open', minWidth: 93,
          },
          {
            headerName: 'SCAC', field: 'scac', columnGroupShow: 'open', minWidth: 105, filter: 'agSetColumnFilter',
          },
        ],
      },
      {
        headerName: 'Mailing Address',
        marryChildren: true,
        children: [
          { headerName: 'Address', field: 'mailingAddress', minWidth: 200 },
          {
            headerName: 'Address2', field: 'mailingAddress2', columnGroupShow: 'open', minWidth: 150,
          },
          {
            headerName: 'City', field: 'mailingCity', columnGroupShow: 'open', minWidth: 165,
          },
          { headerName: 'State', field: 'mailingState', minWidth: 95 },
          { headerName: 'Country', field: 'mailingCountry', minWidth: 110 },
          {
            headerName: 'Postal/Zip', field: 'mailingZip', columnGroupShow: 'open', minWidth: 123,
          },
        ],
      },
      {
        headerName: 'Physical Address',
        marryChildren: true,
        children: [
          { headerName: 'Address', field: 'physicalAddress', minWidth: 200 },
          {
            headerName: 'Address2', field: 'physicalAddress2', columnGroupShow: 'open', minWidth: 150,
          },
          {
            headerName: 'City', field: 'physicalCity', columnGroupShow: 'open', minWidth: 165,
          },
          { headerName: 'State', field: 'physicalState', minWidth: 95 },
          { headerName: 'Country', field: 'physicalCountry', minWidth: 110 },
          {
            headerName: 'Postal/Zip', field: 'physicalZip', columnGroupShow: 'open', minWidth: 123,
          },
        ],
      },

    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.ABIPartiesGrid.saveState('ABIParties') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.ABIPartiesGrid.saveState('ABIParties') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.ABIPartiesGrid.saveState('ABIParties') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.ABIPartiesGrid.saveState('ABIParties') }
    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }

    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadCountryList(), this.LoadParty(), this.LoadCustomerList()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadParty() {
      // Loading Parties
      return axios.get('/abi/party/list', {
        params: {
          UserId: this.$store.state.user.AppActiveUser.Id,
          DBContext: (this.$store.state.user.AppActiveUser.ABIDbContext),
        },
      })
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.ABIPartiesGrid.ResetColumns()
        })
    },
    LoadCountryList() {
      // Loading Country List
      return axios.get('/abi/codes/country')
        .then(rowData => {
          this.CountryOptions = rowData.data
        })
    },
    LoadCustomerList() {
      // Loading Customer List
      return axios.get('/abi/customer/codes', {
        params: {
          UserId: this.$store.state.user.AppActiveUser.Id,
          DBContext: (this.$store.state.user.AppActiveUser.ABIDbContext),
        },
      })
        .then(rowData => {
          this.CustomerOptions = rowData.data
        })
    },
    Open(partyid) {
      this.ResetObject()
      this.PartyID = partyid
      this.$router.push(`/abi/parties/detail/${this.PartyID}`)
    },
    DoubleClickOpenPopup(params) {
      this.ResetObject()
      this.PartyID = params.data.partyId
      this.$router.push(`/abi/parties/detail/${this.PartyID}`)
    },
    CreateNewParty() {
      this.ResetObject()
      this.$refs.CreateParty.show()
    },
    SaveNewParty() {
      this.LoadingStart()
      const PartyDetailModel = {
        CustomerId: this.party.CustomerId.id,
        Active: this.party.IsActive,
        Name: this.party.PartyName,
        FullLegalName: this.party.PartyFullName,
        DBA: this.party.DBA,
        AKA: this.party.Aka,
        PartyCountry: this.party.PartyCountry.countryCode,
        ParentCompany: this.party.ParentCompany,
        ImporterNumber: this.party.ImporterNumber,
        MIDNumber: this.party.MIDNumber,
        PartyType: this.party.PartyType.value,
        EIN: this.party.EIN,
        CBPAssigned: this.party.CBPAssigned,
        SSN: this.party.SSN,
        PassportNumber: this.party.PassportNumber,
        SCAC: this.party.SCAC,
        MailingAddress: this.party.MailingAddress,
        MailingAddress2: this.party.MailingAddress2,
        MailingCity: this.party.MailingCity,
        MailingState: this.party.MailingState,
        MailingCountry: this.party.MailingCountry.countryCode,
        MailingZip: this.party.MailingZip,
        PhysicalAddress: this.party.PhysicalAddress,
        PhysicalAddress2: this.party.PhysicalAddress2,
        PhysicalCity: this.party.PhysicalCity,
        PhysicalState: this.party.PhysicalState,
        PhysicalCountry: this.party.PhysicalCountry.countryCode,
        PhysicalZip: this.party.PhysicalZip,
        IsManufacturer: this.party.IsManufacturer,
        IsSeller: this.party.IsSeller,
        IsBuyer: this.party.IsBuyer,
        IsShipTo: this.party.IsShipTo,
        IsContainerStuffing: this.party.IsContainerStuffing,
        IsConsolidator: this.party.IsConsolidator,
        IsConsignee: this.party.IsConsignee,
        IsImporter: this.party.IsImporter,
        IsBookingParty: this.party.IsBookingParty,
        IsNotifyParty: this.party.IsNotifyParty,
      }

      axios.post('/abi/party/create', PartyDetailModel)
        .then(response => {
          this.$refs.CreateParty.hide()
          this.LoadingEnd()
          this.DisplaySuccess('Party data has been saved.').then(() => {
            this.$router.push(`/abi/party/${response.data}/detail`)
          })
        }).catch(e => {
          this.LoadingEnd()
          this.$refs.CreateParty.hide()
          this.DisplayError('Party data cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          this.$refs.CreateParty.show()
        })
    },
    validateDetail() {
      return new Promise((resolve, reject) => {
        this.$refs.detailRules.validate().then(success => {
          if (success) resolve(true)
          else reject()
        })
      })
    },
    validateType() {
      return new Promise((resolve, reject) => {
        this.$refs.typeRules.validate().then(success => {
          if (success) resolve(true)
          else reject()
        })
      })
    },
    validateIdentifier() {
      return new Promise((resolve, reject) => {
        this.$refs.identifierRules.validate().then(success => {
          if (success) resolve(true)
          else reject()
        })
      })
    },
    validateAddress() {
      return new Promise((resolve, reject) => {
        this.$refs.addressRules.validate().then(success => {
          if (success) resolve(true)
          else reject()
        })
      })
    },
    ResetObject() {
      this.party = {
        InACE: 'No',
        CustomerId: null,
        PartyID: this.$route.params.partyId,
        AddedBy: '',
        AddedDate: '',
        Aka: '',
        CBPAssigned: '',
        DBA: '',
        EIN: '',
        PartyFullName: '',
        ImporterNumber: '',
        LastUpdatedBy: '',
        LastUpdatedDate: '',
        MailingAddress: '',
        MailingAddress2: '',
        MailingCity: '',
        MailingCountry: null,
        MailingState: '',
        MailingZip: '',
        MIDNumber: '',
        PartyName: '',
        ParentCompany: '',
        PartyCountry: null,
        PartyType: null,
        PassportNumber: '',
        PhysicalAddress: '',
        PhysicalAddress2: '',
        PhysicalCity: '',
        PhysicalCountry: null,
        PhysicalState: '',
        PhysicalZip: '',
        SCAC: '',
        SSN: '',
        IsBookingParty: false,
        IsBuyer: false,
        IsConsignee: false,
        IsConsolidator: false,
        IsContainerStuffing: false,
        IsImporter: false,
        IsManufacturer: false,
        IsNotifyParty: false,
        IsSeller: false,
        IsShipTo: false,
        IsActive: true,
      }
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
